// Mobile navigation bar logic, needs JavaScript for initialization
export function initializeMaterializeSidenav() {
  try {
    let elements = document.querySelectorAll('.sidenav');
    let options = {
      edge: 'right'
    };
    M.Sidenav.init(elements, options);
  } catch (error) {
    console.error('Failed to initialize Materialize sidenav! Error: ', error);
  }
}

// Add logic to initialize Material Icons because they're hidden by default to avoid FOUT (flash of unstyled text)
export function initializeMaterializeIcons() {
  try {
    if (!document.fonts) {
      throw new Error("There is no document.fonts object! Font loading API is probably not supported!");
    }
    document.fonts.ready.then(function () {
      let materialIconsLoaded = document.fonts.check('12px Material Icons');
      if (materialIconsLoaded) {
        let stylesheet = window.document.styleSheets[0];
        stylesheet.insertRule('.material-icons { display: inline-block !important; }', stylesheet.cssRules.length);
      } else {
        throw new Error('Material Icons font was not loaded! Will not be showing it...');
      }
    });
  } catch (error) {
    console.error('Failed to show Material Icons after font initialization! Perhaps the browser has bad support for CSS font loading API? Error: ', error);
  }
}

// Tooltip logic that's needed by Materialize
export function initializeMaterializeTooltips() {
  try {
    let elements = document.querySelectorAll('.tooltipped');
    let options = {
      position: 'bottom',
      exitDelay: 0,
      enterDelay: 200,
      inDuration: 200,
      outDuration: 200
    }
    M.Tooltip.init(elements, options);
  } catch (error) {
    console.error('Failed to initialize Materialize tooltips! Error: ', error);
  }
}

// Collapsible logic that's needed by Materialize
export function initializeMaterializeCollapsibles() {
  try {
    let elements = document.querySelectorAll('.collapsible');
    let options = {
      accordion: false
    };
    M.Collapsible.init(elements, options);
  } catch (error) {
    console.error('Failed to initialize Materialize collapsibles! Error: ', error);
  }
}

// Custom lightbox logic which allows using data attributes and swapping out thumbnails for full size images on click
export function initializeMaterializeLightbox() {
  try {
    let elements = document.querySelectorAll('.materialboxed');
    // when images are toggled, check whether they have the data attributes for swapping out to a full sized image
    let options = {
      onOpenStart: function(element) {
        if (element.hasAttribute("data-image-src-big")) {
          let bigSource = element.getAttribute("data-image-src-big");
          element.src = bigSource;
        }
      },
      onCloseStart: function(element) {
        if (element.hasAttribute("data-image-src-small")) {
          let smallSource = element.getAttribute("data-image-src-small");
          element.src = smallSource;
        }
      }
    }
    M.Materialbox.init(elements, options);
  } catch (error) {
    console.error('Failed to initialize Materialize lightbox! Error: ', error);
  }
}

// Modal logic that's needed by Materialize
export function initializeMaterializeModals() {
  try {
    let elements = document.querySelectorAll('.modal');
    let options = {};
    M.Modal.init(elements, options);
  } catch (error) {
    console.error('Failed to initialize Materialize modals! Error: ', error);
  }
}

// Turns out that select elements are a Javascript plugin
export function initializeMaterializeSelect() {
  try {
    let elements = document.querySelectorAll('select');
    let options = {};
    M.FormSelect.init(elements, options);
  } catch (error) {
    console.error('Failed to initialize Materialize select components! Error: ', error);
  }
}

// When one column (or another element) must be limited to the height of another
function updateLimitedHeights() {
  const MEDIUM_SCREEN = 992;
  let screenWidth = screen.width;
  try {
    let elements = document.querySelectorAll('.limit-height-scroll')
    elements.forEach(function(sourceElement){
      try {
        let targetIdProperty = sourceElement.dataset.limitHeightTarget;
        let targetElement = document.getElementById(targetIdProperty);
        if (screenWidth > MEDIUM_SCREEN) {
          let targetElementSize = targetElement.clientHeight;
          sourceElement.style.height = targetElementSize + 'px';
        } else {
          sourceElement.style.height = null; // unset on mobile, no scrolling because same column
        }
      } catch (error) {
        console.error('Failed to limit element heights (iteration)! Error: ', error);
      }
    });
  } catch (error) {
    console.error('Failed to limit element heights (preparation)! Error: ', error);
  }
}

export function initializeMaterializeLimitHeight() {
  try {
    window.addEventListener('resize', function(event) {
      updateLimitedHeights();
    }, {
      passive: true
    });
    updateLimitedHeights();
  } catch (error) {
    console.error('Failed to limit element heights (initialization)! Error: ', error);
  }
}