import {
  initializeMaterializeSidenav,
  initializeMaterializeTooltips,
  initializeMaterializeCollapsibles,
  initializeMaterializeSelect,
  initializeMaterializeModals,
  initializeMaterializeLightbox,
  initializeMaterializeIcons,
  initializeMaterializeLimitHeight,
} from "./materialize-initialization";

function initializeApplication() {
  try {
    console.log('Starting application initialization...');
    let startTime = new Date();
    initializeMaterializeSidenav();
    initializeMaterializeTooltips();
    initializeMaterializeCollapsibles();
    initializeMaterializeSelect();
    initializeMaterializeModals();
    initializeMaterializeLightbox();
    initializeMaterializeIcons();
    initializeMaterializeLimitHeight();
    let endTime = new Date();
    console.log('Application initialization finished...');
    var duration = endTime - startTime;
    console.log('Initialization duration: ', duration, 'ms');
  } catch (error) {
    console.error('Failed to initialize Materialize! This is probably a problem that should be addressed in the individual initialization blocks! Error: ', error);
  }
}

function toggleAllCollapsibles() {
  try {
    let toggleButton = document.querySelectorAll('.collapsible-toggle-button')[0];
    let toggleIcon = document.querySelectorAll('.collapsible-toggle-icon')[0];
    let toggleText = document.querySelectorAll('.collapsible-toggle-text')[0];

    let currentStateIndex = toggleIcon.textContent === 'expand_more' ? 0 : 1;
    let newStateIndex = currentStateIndex ? 0 : 1;

    let states = [
      {
        icon: 'expand_more',
        text: 'Expand All Items'
      },
      {
        icon: 'expand_less',
        text: 'Collapse All Items'
      }
    ];

    // add new application states instantly
    toggleIcon.textContent = states[newStateIndex].icon;
    toggleText.textContent = states[newStateIndex].text;

    // figure out what we should be doing now
    let elements = document.querySelectorAll('.collapsible');
    let shouldExpand = newStateIndex;
    if (shouldExpand) {
      console.log('Expanding all collapsibles...');
    } else {
      console.log('Collapsing all collapsibles...');
    }

    // loop over all elements and toggle them
    for (let element of elements) {
      let collapsibleInstance = M.Collapsible.getInstance(element);
      if (shouldExpand) {
        collapsibleInstance.close(); // there is some stupid bug with Materialize where the first opened item blocks the rest from opening, WTF
        collapsibleInstance.open();
      } else {
        collapsibleInstance.close();
      }
    }
  } catch (error) {
    console.error('Failed to toggle all collapsibles! This is probably caused by wrong setup of application. Error: ', error);
  }
}

function printPage() {
  try {
    console.log('Attempting to print the page...');
    window.print();
  } catch (error) {
    console.error('Failed to print the page! Error: ', error);
  }

}

function navigateToProject(event, projectId) {
  try {
    console.log('Navigating to project: ', projectId);
    event.stopPropagation();
    window.location.href = '/projects/' + projectId;
  } catch (error) {
    console.error('Failed to navigate to project! Error: ', error);
  }
}

if (!window.M.kronis) {
  window.M.kronis = {};
}
window.M.kronis.toggleAllCollapsibles = toggleAllCollapsibles;
window.M.kronis.printPage = printPage;
window.M.kronis.navigateToProject = navigateToProject;
window.M.kronis.initializeApplication = initializeApplication;